import { Controller } from "stimulus"
import consumer from '../channels/consumer';


export default class extends Controller {
  static targets = [
    "contentInput",
    "submit"
  ]

  connect() {
    this.discussionThreadId = this.element.dataset.discussionThreadId
    this.channel = consumer.subscriptions.create({
      channel: 'DiscussionThreadChannel',
      discussion_thread: this.discussionThreadId
    }, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
    });
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  _cableConnected() {
    this.contentInputTarget.disabled = false
    this.submitTarget.disabled = false
  }

  _cableDisconnected() {
    this.contentInputTarget.disabled = true
    this.submitTarget.disabled = true
  }

  messageCallback() {
    this.contentInputTarget.value = ""
  }
}