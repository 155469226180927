import { Controller } from "stimulus"
import debounce from "lodash/debounce"
export default class extends Controller {

  static targets = [
    'mainComponent',
    'updateComponent',
    'searchBarInput',
    'pagingComponent',
    'dateFilterInput',
    'descPar',
    'buttonFilter',
    'select'
  ]

  lastSelectedButton = null;
  pageParams = 1
  searchParams = ""
  sortParams = ""

  connect()
  {
    this.useSearchBar = debounce(this.useSearchBar, 1000).bind(this)
    //améliorable avec la version supérieur de stimulus (targetConnected)
    if(this.hasPagingComponentTarget){
      this.addClickEventOnPaging()
    }
    if(this.hasDateFilterInputTarget)
    {
      let dateFilterDataset = this.dateFilterInputTarget.dataset
      let start_at = dateFilterDataset.startAt
      let end_at = dateFilterDataset.endAt
      flatpickrForList(this, start_at, end_at)
    }
    this.focusOnSearch()
    this.updateClearButtons();
  }

  focusOnSearch() {
    if (this.hasSearchBarInputTarget) {
      const element = this.searchBarInputTarget
      const elementLength = element.value.length
      if (elementLength > 0) {
        element.focus()
        element.setSelectionRange(elementLength, elementLength);
      }
    }
  }

  keyup() {
    this.useSearchBar()
  }

  addClickEventOnPaging()
  {
    //je n'ai pas trouver d'alternative pour changer l'event de will paginate
    this.pagingComponentTarget.querySelectorAll(".page-item a").forEach(a => {
      a.value = new URL(a.href).searchParams.get("page")
      a.removeAttribute("href")
      a.addEventListener('click', event => { this.changePaging(event)})
    })
  }

  changeDate(){
    this.pageParams = 1
    this.applyFilter()
  }


  changePaging(event)
  {
    this.pageParams = event.target.value
    this.applyFilter()
  }

  useSearchBar()
  {
    this.pageParams = 1
    this.applyFilter()
  }

  changeSort(){
    this.descParTarget.dataset.desc = (this.descParTarget.dataset.desc == "true") ? "false" : "true"
    this.applyFilter()
  }

  filterAction(event){
    this.pageParams = 1
    const button = event.currentTarget;
    const filterName = button.dataset.filterName;
    this.buttonFilterTargets.forEach(target => {
      if (target.dataset.filterName === filterName) {
        target.classList.remove('button-selected-filter');
      }
    });
    button.classList.add('button-selected-filter');
    this.applyFilter()
  }

// ****************************

  selectChanged(event) {
    const select = event.target;
    this.toggleClearButton(select);
    if (!select.classList.contains("flatpickr-input")) {
      this.applyFilter()
    }
  }

  selectClear(event) {
    const clearButton = event.currentTarget;
    const select = clearButton.previousElementSibling.querySelector('select');
    const flatpickrInput = clearButton.previousElementSibling.querySelector('.flatpickr-input');
    if (select) {
      select.value = "";
      this.toggleClearButton(select);
    } else if (flatpickrInput) {
      flatpickrInput.flatpickr().clear();
      this.toggleClearButton(flatpickrInput);
    }
    this.applyFilter()
  }
  toggleClearButton(select) {
    const filter = select.closest('.filter');
    if (select.value && !select.value.includes("undefined")) {
      filter.classList.add('active');
    } else {
      filter.classList.remove('active');
    }
  }
  updateClearButtons() {
    this.selectTargets.forEach((select) => {
      this.toggleClearButton(select);
    });
  }

// ******************************
  applyFilter()
  {
    if ("filtersurl" in this.mainComponentTarget.dataset){
      const url = this.mainComponentTarget.dataset.filtersurl
      var paramUrl = {
        sort: this.sortParams,
        desc: this.descParTarget.dataset.desc
      }
      if (this.hasPagingComponentTarget)
      {
        paramUrl.page = this.pageParams
      }

      if (this.hasSearchBarInputTarget)
      {
        paramUrl.q = this.searchBarInputTarget.value.toUpperCase()
      }

      if (this.hasDateFilterInputTarget)
      {
        var datesFilter = this.dateFilterInputTarget.value.split(" - ")
        paramUrl.start_at = datesFilter[0] == "undefined" ? "" : datesFilter[0]
        paramUrl.end_at = datesFilter[1] == "undefined" ? "" : datesFilter[1]
      }

      if (this.hasButtonFilterTarget)
      {
        const activeButtons = this.buttonFilterTargets.filter(button => button.classList.contains('button-selected-filter'));
        activeButtons.forEach(button => {
          const filterName = button.dataset.filterName;
          const filterValue = button.dataset.filterValue;
          paramUrl[filterName] = filterValue;
        });
      }

      if (this.hasSelectTarget) {
        const selects = this.selectTargets
        selects.forEach(select => {
          const filterName = select.dataset.filterName
          const filterValue = select.value
          console.log(select.dataset, filterValue)
          paramUrl[filterName] = filterValue
        })
      }

      fetch(url + ( new URLSearchParams( paramUrl ) ) + '&' + new URLSearchParams(document.location.search))
        .then(response => response.text())
        .then(html => this.updateTargetComponent(html))
    }
  }

  updateTargetComponent(html) {
    var temp = document.createElement('div')
    temp.innerHTML = html
    this.mainComponentTarget.replaceWith(temp)
  }
}
