import { Controller } from "stimulus"
import consumer from '../channels/consumer'
import axios from 'axios'

export default class extends Controller {
  static targets = [
    "message",
    "unread",
    "header",
    "chat"
  ]

  connect() {
    this.gripZone = 40
    this.discussionThreadId = this.element.dataset.discussionThreadId
    this.channel = consumer.subscriptions.create({
      channel: 'DiscussionThreadChannel',
      discussion_thread: this.discussionThreadId
    }, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this)
    });
    this.fetchMessages(true)
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  _cableConnected() {
  }

  _cableDisconnected() {
    this.chatTarget.classList.add('disabled')
  }
  _cableReceived(data) {
    if(data.message_created) {
      this.fetchMessages()
    } else if(data.message_read){
      this.receivedMessageRead(data.message_read)
    } else if(data.removed_user_id){
      if(data.quit == gon.userId) {
        this.redirectToIndex()
      }
    } else if(data.discussion_header){
      this.fetchHeader()
    } else {
      console.log("error")
      console.log(data)
    }
  }

  fetchHeader() {
    var url = '/discussion_threads/'+this.discussionThreadId+'/header'
    fetch(url)
      .then(response => response.text())
      .then(html => this.updateHeader(html))
  }

  updateHeader(html) {
    var temp = document.createElement('div')
    temp.innerHTML = html
    this.headerTarget.replaceWith(temp.firstChild)
  }

  redirectToIndex() {
    window.location.replace('/discussion_threads/') 
  }

  receivedMessageRead(messageId) {
    this.unreadTargets.forEach(elm => {
      if(elm.dataset.id ==  messageId) {
        // remove "unread" from targets
        elm.dataset.discussionThreadsTarget = "message"
      } 
    })
  }

  fetchMessages(firstFetch) {
    var attachedToBottom = this.hasReachBottom()
    var messages = this.messageTargets
    var messagesIds = messages.map( msg => msg.getAttribute("data-id"))
    var lastMessageId = messagesIds.length > 0 ? messagesIds[messagesIds.length - 1] : ""
    fetch('/discussion_threads/'+this.discussionThreadId+'/messages?last_message_id='+lastMessageId)
      .then(response => response.text())
      .then(newMessage => this.ensureMessageUnicity(newMessage, messagesIds))
      .then(html => this.chatTarget.insertAdjacentHTML('beforeend', html))
      .then(() => this.scrollToTheRigthPlace(firstFetch, attachedToBottom))
      .then(() => this.checkAutomaticReadCondition()) // maybe redondante with the scroll but we need it when there is no scrollbar
  }

  scrolled() {
    this.checkAutomaticReadCondition()
  }

  checkAutomaticReadCondition() {
    if (this.hasReachBottom()  && this.hasUnreadTarget) {
      this.sendRead()
    }
  }

  sendRead() {
    axios.post('/discussion_threads/'+this.discussionThreadId+'/read')
  }

  hasReachBottom() {
    return this.chatTarget.scrollHeight - this.chatTarget.scrollTop < this.chatTarget.offsetHeight + this.gripZone
  }

  scrollToTheRigthPlace(firstFetch, attachedToBottom) {
    if (attachedToBottom) {
      if (firstFetch && this.hasUnreadTarget) {
        this.scrollToFirstUnreadMessage()
      } else {
        this.scrollToBottom()
      }
    }
  }

  scrollToFirstUnreadMessage() {
    // here we use unreadTarget with no s to have the first of the list
    var firstUnreadMessage = this.unreadTarget
    var nouveau = this.addNouveau(firstUnreadMessage)
    var scrollPos = nouveau.offsetTop - nouveau.offsetHeight
    this.chatTarget.scrollTo({top: scrollPos})
  }

  addNouveau(firstUnreadMessage) {
    var nouveau = document.createElement('div')
    nouveau.classList.add('nouveau')
    nouveau.innerHTML = 'nouveau'
    firstUnreadMessage.parentNode.insertBefore(nouveau, firstUnreadMessage)
    return nouveau
  }

  scrollToBottom() {
    this.chatTarget.scrollTop = this.chatTarget.scrollHeight
  }

  ensureMessageUnicity(newMessage, messagesIds) {
    var template = document.createElement('div')
    template.innerHTML = newMessage.trim()
    messagesIds.forEach(id => {
      var clone = template.querySelector("div[data-id='" + id + "']")
      clone && clone.remove()
    })
    return template.innerHTML
  }
}