// Adapted from https://github.com/stimulus-components/stimulus-clipboard/blob/v4.0.1/src/index.ts
// Reasons why copypasta instead of using package:
// - >2.0.0 packages depends on hotwire 3 we don't have (yet)
// - Using 2.0.0 package is possible but don't use navigator.clipboard api which allow to copy hidden values (which I want)
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'source']
  static values = {
    successContent: String,
    successDuration: Number
  }

  connect() {
    if (!this.hasButtonTarget) return

    this.originalContent = this.buttonTarget.innerHTML
  }

  copy(event) {
    event.preventDefault()

    const text = this.sourceTarget.innerHTML || this.sourceTarget.value

    navigator.clipboard.writeText(text).then(() => this.copied())
  }

  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerHTML = this.successContentValue

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent
    }, this.successDurationValue || 2000)
  }
}