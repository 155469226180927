// DEBT: Do the get in the controller instead of replacing the nil
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "recipient",
    "addItem",
    "body"
  ]

  OpenForm(){
    this.linkToServiceItemForm()
  }
  
  linkToServiceItemForm(){
    const params = {dashboard: true}
    this.addItemTarget.href = this.addItemTarget.href.replace('/nil/', `/${this.recipientTarget.value}/`)
    this.addItemTarget.href = this.addItemTarget.href + '?' + ( new URLSearchParams( params ) )
    return this.addItemTarget.href;
  }
}