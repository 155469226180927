import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'rowAction' //reference link for the action 
  ]

  toggleCheckbox(event) {
    var checked = event.srcElement.checked
    this.rowActionTargets.forEach(element => {
      element.dataset.selected = checked
    });
  }
}
