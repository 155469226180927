import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "displayFormsButton",
    "forms"
  ]

  displayForms() {
    this.formsTarget.hidden = false
    this.displayFormsButtonTarget.hidden = true
  }
}
