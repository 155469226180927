import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'button', //is disabled when no checkbox are selected
    'rowAction', //reference link for the action 
    'rowCheckbox', //checkboxs for each row  
    'rowCheckboxContainer' //container of checkboxes, hidden when no checkbox are selected
  ]

  connect() {
    if(this.rowCheckboxTargets.length == 0) {
      this.buttonTargets.forEach(button => {button.hidden = true})
      this.rowCheckboxContainerTargets.forEach(container => {container.hidden = true})
    }
  }

  fire(event) {
    event.preventDefault()
    var actiontype =  event.currentTarget.dataset.type
    var selectedActions =  this.rowActionTargets.filter(target => target.dataset.selected == 'true')
    var actions = selectedActions.filter(target => target.dataset.type == actiontype)
    var promiseArray = []
    var confirmText = event.currentTarget.dataset.confirmtext + ' (' + actions.length + ')'
    if(actions.length > 0) {
      if (confirm(confirmText)) {
        actions.forEach(action => {
          var params = {
            url:  action.href,
            method: action.dataset.method,
            headers: {
              'Accept': 'application/json'
            }
          }
          promiseArray.push(axios(params))
        });
        Promise.all(promiseArray).then(values => { location.reload() } )
      }
    }
  }

  checkboxChange() {
    var disabled = this.rowCheckboxTargets.filter(target => target.checked).length == 0
    this.buttonTargets.forEach(element => {
      element.disabled = disabled
    })
  }

  toggleCheckboxAll(event) {
    var checked = event.currentTarget.checked
    this.rowCheckboxTargets.forEach(element => {
      element.checked = checked
      var evt = new Event("change")
      element.dispatchEvent(evt)
    });
  }
}
