import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "button"]

  connect() {
    this.updateButtonVisibility();
    window.addEventListener('resize', () => this.updateButtonVisibility());
  }

  disconnect() {
    window.removeEventListener('resize', () => this.updateButtonVisibility());
  }

  updateButtonVisibility() {
    const isOverflowing = this.containerTarget.scrollHeight > this.containerTarget.clientHeight;
    this.buttonTarget.style.display = isOverflowing ? "block" : "none";
  }
}