import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "evaluationResult",
    "evaluationValues",
    "saveEvaluationButton",
    "radioGroup",
    "radio"
  ]
  static classes = [
    "valueChecked",
    "showResult"
  ]

  connect() {
    this.saveEvaluationButtonDisable()
  }

  radioChanged(event) {
    const radioGroup = this.radioGroupTargets.find( (rg) => {
      return rg.dataset.evaluationValue == event.currentTarget.dataset.evaluationValue
    })
    radioGroup.classList.add(this.valueCheckedClass)

    this.setResult('')
    this.saveEvaluationButtonDisable()
  }

  setResult(result, evaluation) {
    if( result && evaluation ) {
      this.element.classList.add(this.showResultClass)
      this.evaluationResultTarget.textContent = result
      this.evaluationValuesTarget.value = JSON.stringify(evaluation)
    } else {
      this.element.classList.remove(this.showResultClass)
      this.evaluationResultTarget.textContent = ''
      this.evaluationValuesTarget.value = ''
    }
  }

  evaluationSubmit(event) {
    const [data] = event.detail
    this.setResult(data.evaluation, data.values)
    this.saveEvaluationButtonDisable()
  }

  saveEvaluationButtonDisable = () => {
    const disable = !this.evaluationValuesTarget.value
    this.saveEvaluationButtonTarget.disabled = disable
  }
}
