import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "panel",
    "link"
  ];

  toggle(event) {
    event.preventDefault()
    this.linkTarget.classList.toggle('active-item')
    this.linkTarget.classList.toggle('collapsed')
    this.panelTarget.classList.toggle('show');
  }
}