import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
  ]
  static values = { url: String }

  connect() {
    if (sessionStorage.getItem("banner-close") ==  "true") {
      this.element.style.display = "none";
    }
  }

  close() {
    this.element.style.display = "none";
    sessionStorage.setItem("banner-close", "true");
  }
}
