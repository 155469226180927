import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'kpi_value',
    'dateFilterInput'
  ]

  connect(){
    flatpickrInitialize(this, this.dateFilterInputTarget.dataset.defaultDate )
  }

  applyFilter()
    {
    const path = this.dateFilterInputTarget.dataset.url
    var datesFilter = this.dateFilterInputTarget.value.split(" - ")
    var params = {
      start_at: datesFilter[0],
      end_at: datesFilter[1],
    }
    if (this.dateFilterInputTarget.dataset.targetValue)
    {
      params.target_value = this.dateFilterInputTarget.dataset.targetValue
    }
    fetch(path + '?' + ( new URLSearchParams( params ) ) + '&' + new URLSearchParams(document.location.search))
      .then(response => {
        if (response.ok)
        {
          response.text().then(html => this.updateData(html))
        }
      })

    }

  updateData(html) {
    if(html)
    {
      this.kpi_valueTarget.innerHTML = html
    }
  }
}