import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    'quantityContainer',
    'quantity'
  ]

  enabledQuantity(event){
    if ((event.target.checked === true )) {
      this.quantityTargets.forEach( (l)=> { 
        if(l.id == event.target.id){
          l.disabled = false
          }
        })
      this.quantityContainerTargets.forEach( (t)=> { 
        if(t.dataset.id == event.target.id){
          t.classList.remove('hidden')
          }
        })
    } 
    else {
      this.quantityTargets.forEach( (l)=> { 
        if(l.id == event.target.id){
          l.disabled = true
          }
        })
      this.quantityContainerTargets.forEach( (l)=> { 
        if(l.dataset.id == event.target.id){
          l.classList.add("hidden")
          }
        })
    } 
  }
}