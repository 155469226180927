import { Controller } from "stimulus"
import consumer from '../channels/consumer';


export default class extends Controller {
  static targets = [
    "active",
    "notifiable",
    "list"
  ]

  connect() {
    this.channel = consumer.subscriptions.create({
      channel: 'NotificationChannel',
    }, {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this)
    });
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel)
  }

  _cableConnected() {
  }

  _cableDisconnected() {
  }
  
  _cableReceived(data) {
    var url = this.hasActiveTarget ? '/discussion_threads/list?id=' + this.activeTarget.dataset.discussionThreadId : '/discussion_threads/list'
    fetch(url)
      .then(response => response.text())
      .then(html => this.updateDiscussionList(html))
  }

  updateDiscussionList(html) {
    var temp = document.createElement('div')
    temp.innerHTML = html
    this.notifiableTarget.replaceWith(temp.firstChild)
  }

  openList(event) {
    event.preventDefault()
    this.listTargets.forEach(list => {
      list.hidden = event.target.parentElement != list.parentElement
    })
  }
}