// DEBT: split this controller on 2 or 3 smaller controller (because this one is big)
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'answer',
    'category',
    'modal',
    'btn',
    'categories',
    'modalBody',
    'modalCategory',
    'badge',
    'icon', 
    'checkbox',
    'btnNext'
  ]

  showAnswer(event){
    this.displayEachCategoryAnswer(this.answerTargets, event)
    this.hideUnclickedCategory(this.categoriesTargets, event)
    this.toggleIconOnClick(this.iconTargets, event)
    this.btnTarget.classList.toggle('toggle-display')
    this.btnNextTarget.classList.toggle('hidden')
  }

  showItem(event){
    this.countSelectedAnwser(this.checkboxTargets,this.badgeTargets, event)
    this.displayModalAnswerAndCategory(this.modalBodyTargets,this.modalCategoryTargets,event)
  }

  showModal(event){
    event.preventDefault();
    const modal = new Modal(this.modalTarget);
    modal.show();
    this.modalTarget.classList.remove('hidden')
  }

  closeTheOpenCategory(){
    this.answerTargets.forEach( (l) => { l.classList.add('hidden')})
    this.categoriesTargets.forEach( (l) => {l.classList.remove('active-category')})
    this.btnTarget.classList.toggle('toggle-display')
    this.btnNextTarget.classList.add('hidden')
    this.iconTargets.forEach( (l) => { 
      l.classList.remove('fa-angle-up')
      l.classList.add('fa-angle-down')
    })
  }

  displayEachCategoryAnswer(target,event){
    target.forEach( (el) => { 
      el.classList.toggle('hidden')
      if (el.dataset.category != event.target.dataset.category){
        el.classList.add('hidden')
      } 
    })
  }

  hideUnclickedCategory(target, event){
    target.forEach( (l) => { 
      if(l.dataset.category != event.target.dataset.category){
        l.classList.toggle('active-category')
      }
    })
  }

  toggleIconOnClick(target, event){
    target.forEach( (l) => { 
      if(l.dataset.category == event.target.dataset.category){
        l.classList.toggle('fa-angle-down')
        l.classList.toggle('fa-angle-up')
      } else{
        l.classList.add('fa-angle-down')
        l.classList.remove('fa-angle-up')
      } 
    })
  }

  countSelectedAnwser(elems,item,event){
    var count = 0
    for (var i = 0; i < elems.length ; i++) {
      if ((elems[i].dataset.category == event.target.dataset.category)&& (elems[i].checked === true)){
        count++;
      } 
    }
    item.forEach((el)=> { 
      if((el.dataset.category == event.target.dataset.category)){
        if (count > 0){
          el.innerHTML = count
          this.btnTarget.disabled = false
        } else{
          el.innerHTML = " "
          this.btnTarget.disabled = true
        }
      } 
    })
  }

  displayModalAnswerAndCategory(answer,category,event){
    if ((event.target.checked === true )) {
      answer.forEach( (l)=> { 
        if(l.dataset.key == event.target.value){
          l.classList.remove('hidden')
          }
        })
      category.forEach( (el)=> { 
        if((el.dataset.category == event.target.dataset.category)){
          el.classList.remove('hidden')
        } 
      })
      } 
    else {
      answer.forEach( (l)=> { 
        if(l.dataset.key == event.target.value){
          l.classList.add('hidden')
        }
      })
      category.forEach( (el)=> { 
        if((el.dataset.category == event.target.dataset.category)){
          el.classList.add('hidden')
        } 
      })
    }
  }
}