import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    'quantityDetail'
  ]

  showQuantityDetail(event){
    this.quantityDetailTarget.classList.toggle('hidden')
  }
}