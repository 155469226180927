// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
// We load *_controller.js files under app, but even if it includes those
// in app/components we must load these individiually so they are not referenced
// with "components--something"
// DEBT: should avoid loading twice components in app/components and app/javascript
// IDEA: load but without taking account of first level under app (as zeitwerk do)
// is possible?
const contextComponentsFromApp = require.context("../../", true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents).concat(
      definitionsFromContext(contextComponentsFromApp)
    )
  )
)

import Remote from 'stimulus-remote-rails'
application.register('remote', Remote)

import NestedForm from 'stimulus-rails-nested-form'
application.register('nested-form', NestedForm)

import ContentLoader from 'stimulus-content-loader'
application.register('content-loader', ContentLoader)
