import { Controller } from "stimulus"

export default class extends Controller {
  openMobileUrl(event)
  {
    var url = event.target.dataset.url
    if (MobileInterface.get()) {
      MobileInterface.get().openUrl(url)
    }
    else {
      window.open( url,'_blank');
    }
  }
}
