import { Controller } from "stimulus"

//change when we will have stimulus 3 and his targetConnected functions
const listComponentMutationObserverCallback = function (mutations ) {
  mutations.forEach(function (mutation) {
    if (mutation.addedNodes.length == 1 && mutation.addedNodes[0].constructor  == HTMLDivElement && mutation.addedNodes[0].classList.contains('body-list-component')){
      const listComponent = mutation.target  
      var selectInfo = listComponent.querySelector('.select-list > select')
      listComponentShowValue(listComponent, selectInfo)
      }
    })
}

function listComponentShowValue(listComponent, selectInfo)
{
  const rowComponents = listComponent.querySelectorAll('.row-component');
  const idxInfo = selectInfo.selectedIndex
  rowComponents.forEach(row => 
  {
    const divInfos = row.querySelectorAll('.info-row');
    for (let i = 0; i < divInfos.length; i++) {
      if (i == idxInfo)
      {
        divInfos[i].classList.remove('hide-info-row')
      }
      else{
        divInfos[i].classList.add('hide-info-row')
      }
    }
  })
}
export default class extends Controller {

  static targets = [
    'listComponent',
    'selectInfo'
  ]

  connect()
  {
    // DEBT still usefull? 
    this.setUpMutationObserverOnBody()
    this.showValue()
    $('[data-toggle="tooltip"]').tooltip()
  }

  setUpMutationObserverOnBody()
  {
    const observer = new MutationObserver(listComponentMutationObserverCallback) 
    observer.observe( this.listComponentTarget, {
      childList: true
    })

  }

  showValue()
  {
    listComponentShowValue(this.listComponentTarget, this.selectInfoTarget)
  }

  // Custom Action
  goToUrl(event) {
    const url = event.currentTarget.dataset.listComponentUrlValue;
    const targetBlank = event.currentTarget.dataset.listComponentTargetBlank === "true";
    if (url) {
      if (targetBlank) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  }
}