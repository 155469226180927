import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [
    "button", 
    "member"
  ]

  connect() {
    this.update_members()
  }

  update(event) {
    event.target.classList.toggle('active')
    this.update_members()
  }

  update_members() {
    var sectors_ids = this.get_sectors_ids()
    if(sectors_ids.length > 0) {
      this.memberTargets.forEach(element => {
        var user_sector_ids = element.dataset['sectorsId'].split(',').filter(i => i)
        if(user_sector_ids.length == 0 && sectors_ids.includes("without_sector")) {
          element.removeAttribute("disabled");
        } else {
          if (user_sector_ids.some(item => sectors_ids.includes(item))) {
            element.removeAttribute("disabled");
          } else {
            element.setAttribute("disabled", true);
          }
        }
      });
    }
  }

  get_sectors_ids() {
    return this.buttonTargets.flatMap(x => x.classList.contains('active') ? [x.dataset.id] : [])
  }
}