import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["info", "button"];

  connect() {
    this.updateButtonVisibility();
    window.addEventListener('resize', () => this.updateButtonVisibility());
  }

  disconnect() {
    window.removeEventListener('resize', () => this.updateButtonVisibility());
  }

  updateButtonVisibility() {
    const isOverflowing = this.infoTarget.scrollHeight > this.infoTarget.clientHeight;
    this.buttonTarget.style.display = isOverflowing ? "block" : "none";
  }
}