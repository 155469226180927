
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "index",
    "about",
    "chat",
    "previous"
  ]

  showPrev(event) {
    event.preventDefault()
    this.indexTarget.classList.toggle('active')
  }

  closeIndex(event) {
    if(event.target == this.previousTarget || this.previousTarget.contains(event.target)) {
      return
    }
    this.indexTarget.classList.remove('active')
  }

  showInfo(event) {
    event.target.classList.toggle('active')
    this.aboutTarget.classList.toggle('active')
    this.chatTarget.classList.toggle('active')
    this.previousTarget.classList.toggle('hidden')
  }
}