import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    'modal',
    'modalContent'
  ]

  openModal() {
    const modal = new Modal(this.modalTarget);
    modal.show();
    this.modalTarget.classList.remove('hidden')
  }

  setModalContent(event) {
    console.log(this.modalTarget)
    const mealPrepareId = event.currentTarget.dataset.mealPrepareId;
    const saad = event.currentTarget.dataset.saad;
    const recipient = event.currentTarget.dataset.recipient;
    const modalTarget = this.modalTarget;
    const modalContent = this.modalContentTarget;
    fetch(`/app/saads/${saad}/recipients/${recipient}/meal_prepares/${mealPrepareId}/meal_prepare_archives/new`)
      .then(response => response.text())
      .then(data => {
        modalContent.innerHTML = data;
        this.openModal();
      });
  }
}