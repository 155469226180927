import { Controller } from "stimulus"
import axios from 'axios'


export default class extends Controller {
  static targets = [
    "form",
    "contentBox",
    "container",
    "title",
    "anchor",
    "toDisable"
  ]
  static values = { url: String }

  connect() {
    this.load(0)
  }

  load(scrollTop) {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
      .then(() => this.contentBoxTarget.scrollTop = scrollTop)
      .then(() => this.scroll_tracker())
  }

  scroll_tracker(){
    this.anchorTargets.forEach(element => {
      element.parentElement.classList.remove("active")
    });
    let current_title = ""
    if(this.contentBoxTarget.scrollTop + this.contentBoxTarget.offsetHeight == this.contentBoxTarget.scrollHeight){
      current_title = this.titleTargets[this.titleTargets.length - 1]
    } else if(this.contentBoxTarget.scrollTop == 0 ){
      current_title = this.titleTargets[0]
    } else {
      this.titleTargets.every(element => {
        if(element.parentElement.offsetTop < this.contentBoxTarget.scrollTop){
          current_title = element
          return true
        } else {
          return false
        }
      });
    }
    let anchor = this.anchorTargets.find(target => target.dataset.category == current_title.id)
    anchor.parentElement.classList.add("active")
  }

  scroll_to(event) {
    event.preventDefault()
    let category = event.currentTarget.dataset.category
    document.getElementById(category).scrollIntoView();
  }

  submitForm(event) {
    event.preventDefault()
    let that = this
    // DEBT Don't use JQuery but stimulus target and axios
    // WIP make feedback
    const $form = $(this.formTarget)

    $.ajax({
      url: $form.prop('action'),
      beforeSend: function( xhr ) {that.toDisableTarget.disabled = "disabled"},
      method: $form.prop('method'),
      data: $form.serialize(),
      dataType: 'json'
    }).done(function(e) {
      that.load(that.contentBoxTarget.scrollTop)
    });
  }

  toggle_group(event) {
    let containerName = event.currentTarget.dataset.name
    let container = this.containerTargets.find(target => target.dataset.name == containerName)
    if(container != null) {
      container.classList.toggle("hide_subgroup");
    }
  }
}
