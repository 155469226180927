import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    'quantity',
    'quantityContainer',
    'foodList',
    'btnClose',
    'btnMorning',
    'btnAfternoon',
    'btnNight',
    'menuContainer',
    'submitContainer',
    'hydrationContainer'
  ]

  initialize() {
    this.innerText = this.btnCloseTarget.innerHTML;
  }

  closeFoodList(){
    this.showMenuButton()
    this.btnCloseTarget.classList.add('hidden')
    this.foodListTargets.forEach( (t)=> { 
      t.classList.add('hidden')
      })
    this.submitContainerTarget.classList.add('hidden')
    this.btnCloseTarget.innerHTML = this.innerText
    this.hydrationContainerTarget.classList.add('hidden')
  }

  showFoodList(event){
    this.showFood(event)
  }


  enabledQuantity(event){
    if ((event.target.checked === true )) {
      this.quantityTargets.forEach( (l)=> { 
        if(l.id == event.target.id){
          l.disabled = false
          }
        })
      this.quantityContainerTargets.forEach( (t)=> { 
        if(t.dataset.id == event.target.id){
          t.classList.remove('hidden')
          }
        })
    } 
    else {
      this.quantityTargets.forEach( (l)=> { 
        if(l.id == event.target.id){
          l.disabled = true
          }
        })
      this.quantityContainerTargets.forEach( (l)=> { 
        if(l.dataset.id == event.target.id){
          l.classList.add("hidden")
          }
        })
    } 
  }

  hideMenuButton(){
    this.menuContainerTarget.classList.add('hidden')
  }

  showMenuButton(){
    this.menuContainerTarget.classList.remove('hidden')
  }

  showFood(event){
    this.hideMenuButton()
    this.foodListTargets.forEach( (t)=> { 
      if(t.dataset.time == event.target.dataset.time ){
        t.classList.remove('hidden')
        this.btnCloseTarget.classList.remove('hidden')
      }
    })
    this.submitContainerTarget.classList.remove('hidden')

    this.btnCloseTarget.innerHTML += `${event.currentTarget.dataset.label}`
  }
}