import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details", "button"];

  connect() {
  }

  loadDetails(event) {
    const button = event.currentTarget;
    const requestId = button.dataset.requestId;
    const resultId = button.dataset.resultId;
    const detailDiv = this.detailsTarget;

    // Si les détails sont déjà visibles, on les replie et on change le texte du bouton
    if (detailDiv.style.display === 'block') {
      detailDiv.style.display = 'none';
      button.textContent = 'Charger les détails';
      return;
    }

    // Vérifie si les données sont déjà chargées pour éviter de refaire un appel AJAX
    if (detailDiv.innerHTML.trim() !== 'Chargement...' && detailDiv.innerHTML.trim() !== '') {
      detailDiv.style.display = 'block';
      button.textContent = 'Masquer les détails';
      return;
    }

    // Sinon, on fait l'appel AJAX pour charger les données
    fetch(`/lab/log_results/${requestId}?result_id=${resultId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des données.');
        }
        return response.json();
      })
      .then(data => {
        detailDiv.style.display = 'block';
        detailDiv.innerHTML = `<pre>${JSON.stringify(data, null, 2)}</pre>`;
        button.textContent = 'Masquer les détails'; // Met à jour le texte du bouton
      })
      .catch(error => {
        detailDiv.innerHTML = "<em>Erreur lors du chargement des détails.</em>";
      });
  }
}
