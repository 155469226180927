import NestedForm from 'stimulus-rails-nested-form'
export default class extends NestedForm  {

  connect() {
    super.connect()
    const firstComment = this.commentTargets.slice(0, this.answerTarget.value)
    firstComment.forEach( (l) => {
      l.classList.remove('hidden')})
  }

  static targets = [
    'answer',
    'comment'
  ]

  showValue(event){
    const arrNew = this.commentTargets.slice(0, this.answerTarget.value)
    this.commentTargets.forEach( (l) => {
      l.classList.add('hidden')})
    if (event.target.value == arrNew.length){
      arrNew.forEach( (l) => {
        l.classList.remove('hidden')})
    }
  }
}