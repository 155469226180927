import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["colorPreview", "colorPicker"]

  connect() {
    console.log('hey')
    this.updateColor(); // Met à jour la couleur au chargement si nécessaire
  }

  updateColor() {
    const selectedColor = this.colorPickerTarget.value;
    this.colorPreviewTarget.style.backgroundColor = selectedColor;
  }
}