import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];
  static values = { toggleClass: String };

  toggle(event) {
    const toggleClass = this.toggleClassValue || "d-none";
    const targetType = event.currentTarget.dataset.toggleTarget;

    this.toggleableTargets.forEach(element => {
      if (!targetType || element.classList.contains(targetType)) {
        element.classList.toggle(toggleClass);
      }
    });
    event.currentTarget.classList.toggle("active");
  }
}