import { Controller } from "stimulus"

export default class extends Controller {

  askPushToken(event) {
    MobileInterface.get().askPushToken()
  }

}

document.addEventListener('turbolinks:visit', () => {
    const event = new CustomEvent("update-push-token")

    if ( MobileInterface.get())
    {
      if (!MobileInterface.get().deviceTokenAlreadyExist())
      {
        window.dispatchEvent(event)
      }
    }
})
