// Attach controller to a form and attach "submit" target(s).
// Targets will be disabled if form is invalid.
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "submit"
  ]

  initialize() {
    this.isDisable = this.isDisable.bind(this)
  }

  connect() {
    this.isDisable()
    this.element.addEventListener('input', this.isDisable)
  }

  disconnect() {
    this.element.removeEventListener('input', this.isDisable)
  }

  isDisable() {
    const disabled = !this.element.checkValidity()
    this.submitTargets.forEach( (el) => el.disabled = disabled )
  }
}
