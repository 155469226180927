import { Controller } from "stimulus"
import Cookies from 'js-cookie'
export default class extends Controller {
  static targets = [
    "toggleMenu",
    "toggleArrow",
    "menuDrawer",
    "profileMenuDrawer",
    "header",
    "trunk"
  ]

  toggleMenu(event) {
    if (this.toggleMenuTarget.classList.contains("active") ) {
      this.closeAll()
    } else {
      this.toggleMenuTarget.classList.add('active')
      if (event.currentTarget.dataset.type == "profile") {
        this.profileMenuDrawerTarget.classList.add('active')
      } else {
        this.menuDrawerTarget.classList.add('active')
      }
    }
  }

  hide(event) {
    if (event && (this.headerTarget.contains(event.target) || this.profileMenuDrawerTarget.contains(event.target) || this.menuDrawerTarget.contains(event.target))) {
      return
    }
    this.closeAll()
  }

  closeAll() {
    this.toggleMenuTarget.classList.remove('active')
    if(this.hasMenuDrawerTarget) {this.menuDrawerTarget.classList.remove('active')}
    this.profileMenuDrawerTarget.classList.remove('active')
  }

  toggleArrow(event) {
    this.toggleArrowTarget.classList.toggle('left')
    this.menuDrawerTarget.classList.toggle('squeezed')
    this.trunkTarget.classList.toggle('squeezed')
    Cookies.set('menu_squeezed', Cookies.get('menu_squeezed') == "true" ?  "false" : "true")
  }
}