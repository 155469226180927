import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  copy(event) {
    const button = event.currentTarget;
    const content = button.getAttribute('data-content');
    navigator.clipboard.writeText(content);
  }
}