import { Controller } from "stimulus"

import axios from 'axios'

export default class extends Controller {
  static targets = [
    'selectInput',
    'selectSaad',
    'selectRole',
    'contentInput',
    'contentInputContainer',
    'editableCsvInput',
    'draftButtons',
    'downloadErrorLines',
    'downloadEmptyCsv',
    'selectSector',
    'toggleButton'
  ]

  connect() {
    this.draftMap = new Map()
    this.draftMap.set("employees", "EmployeeDraft")
    this.draftMap.set("families", "FamilyDraft")
    this.draftMap.set("recipients", "RecipientDraft")
    this.structureId = ""
    this.selectedRole = ""
    this.content = ""
    this.errorslist = ""
    this.draftId = ""
    this.roleAndStructureSelected = false

    console.log('connect')
    if(this.selectInputTargets){

    }
    this.checkSelect()
  }

  selectChange() {
    console.log('selectChange')
    this.checkSelect()
  }

  checkSelect() {
    if(this.selectInputTargets.map( k => k.value).indexOf("") == -1) {
      this.structureId = this.selectSaadTarget.value
      this.selectedRole = this.selectRoleTarget.value
      this.roleAndStructureSelected = true
      this.getDraft()
    } else {
      this.content = this.errorslist = this.draftId = ""
      this.roleAndStructureSelected = false
      this.updateContentComponent()
    }
    this.getSectorField()
  }

  getSectorField() {
    var sectorInSaad = false
    var e = this.selectSaadTarget
    var saad_name =  e.options[e.selectedIndex].text
    var optgroups = this.selectSectorTarget.children

    // remove selected option if not in current Saad
    if (this.selectSectorTarget.selectedIndex != -1){
      var saadFromSelectedSector = this.selectSectorTarget.selectedOptions[0].parentNode.label
      if (saadFromSelectedSector != saad_name) {
        this.selectSectorTarget.selectedIndex = -1
      }
    }

    // hide options if not in current Saad
    for (let index = 0; index < optgroups.length; index++) {
      optgroups[index].hidden = true
      if(optgroups[index].children.length != 0 && optgroups[index].label == saad_name){
        optgroups[index].hidden = false
        sectorInSaad = true
      }
    }

    // hide selector if no options available
    this.selectSectorTarget.labels[0].hidden = sectorInSaad ? false : true
    this.selectSectorTarget.hidden = sectorInSaad ? false : true
  }

  getDraft() {
    var that = this
    axios.get('/admin/structures/'+ this.structureId + '/user_drafts/', {
      headers: {
        'Accept': 'text/html'
      },
      params: {
        type: this.draftMap.get(this.selectedRole),
        id: ""
      }
    })
    .then(function (response) {
      that.content = response.data
      that.updateContentComponent()
    });
  }

  updateContentComponent() {
    let draft_exist = this.content && this.content['error'] != 'Not found'
    this.contentInputTarget.required = !draft_exist && this.roleAndStructureSelected
    this.contentInputTarget.disabled = draft_exist || !this.roleAndStructureSelected
    this.contentInputContainerTarget.hidden = draft_exist || !this.roleAndStructureSelected
    this.editableCsvInputTarget.hidden = !draft_exist
    if(draft_exist) {
      this.editableCsvInputTarget.innerHTML = this.content
    }
  }

  toggleNonErrorRows(event) {
    event.preventDefault()
    this.showOnlyErrors = !this.showOnlyErrors
    const rows = this.editableCsvInputTarget.querySelectorAll('tbody tr')
    rows.forEach(row => {
      if (!row.classList.contains('error-row')) {
        row.classList.toggle('d-none', this.showOnlyErrors)
      }
    })
    this.toggleButtonTarget.innerText = this.showOnlyErrors ? 'Afficher toutes les lignes' : 'Afficher uniquement les erreurs'
  }
}