import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    'inputField',
    'inputFieldContainer',
    'textField',
    'addInput'
  ]

  initialize() {
    this.count = 0;
  }

  addInputField(event){
    event.preventDefault()
    let can_add_field = true
    this.textFieldTargets.forEach( (l) => {
      if(l.value == ""){
        can_add_field = false
      }
    })
    if(can_add_field) {
      const newInput = this.inputFieldTarget.cloneNode(true);
      newInput.querySelector(".food-name").value = ""
      this.inputFieldContainerTarget.appendChild(newInput);
      newInput.classList.remove('hidden')
      this.addInputTarget.disabled = true
    }
  }

  enabledRangeField(){
    this.addInputTarget.disabled = false
  }
}