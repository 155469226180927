import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'graph',
    'dateFilterInput'
  ]

  connect(){
    const flatpickrDate = this.dateFilterInputTarget.dataset.defaultDate
    flatpickrInitialize(this, flatpickrDate, 5)
  }

  applyFilter(){
    const graph = this.graphTarget
    const graph_id = graph.firstElementChild.id
    const path = this.dateFilterInputTarget.dataset.url
    var datesFilter = this.dateFilterInputTarget.value.split(" - ")
    const params = {
      start_at: datesFilter[0],
      end_at: datesFilter[1],
      target_value: graph_id }
    Chartkick.charts[graph_id].updateData(path + '?' + ( new URLSearchParams( params ) ) + '&' + new URLSearchParams(document.location.search) );
  }
}