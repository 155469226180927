import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [
        'modalBackdrop',
        'modal',
        'img']

    sendHttp(url)
    {
        MobileInterface.get().startDownload()
        const Http = new XMLHttpRequest();
        Http.open("GET", url);
        Http.responseType = "arraybuffer";
        Http.send();
        return Http
    }

    formatByteArry(res)
    {
        var byteArray = new Uint8Array(res);
        var arr = new Uint8Array(byteArray.length);
        for(var i = 0; i < byteArray.length; i++) {
            arr[i] = byteArray[i];
        }
        return arr
    }

    openFile(event)
    {
        var url = event.target.dataset.url
        var type = event.target.dataset.type
        if(type.includes('image'))
        {
            this.imgTarget.src = url
            this.modalTarget.classList.add("show")
            this.modalBackdropTarget.classList.add("modal-backdrop", "show")
        }
        else
        {
            if (MobileInterface.get())
            {
                const Http = this.sendHttp(url)
                Http.onreadystatechange = (e) => {
                    if(Http.readyState === 4 && Http.status === 200) {
                        if(type.includes('pdf'))
                        {
                            MobileInterface.get().sendPdf(this.formatByteArry(Http.response))
                        }
                        else
                        {
                            MobileInterface.get().sendFile(event.target.dataset.filename, this.formatByteArry(Http.response))
                        }
                    }
                }    
            }
            else
            {
                window.open( url,'_blank');
            }
        }
    }

    closePicture(event){
        if(event.target.classList.contains("close-picture")){
            this.modalTarget.classList.remove("show")
            this.modalBackdropTarget.classList.remove("modal-backdrop")
            this.modalBackdropTarget.classList.remove("show")
            this.imgTarget.src = ""
        }
    }
}