import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "dateInput"
  ]

  initialize() {
    this.configDateInput = this.configDateInput.bind(this)
  }

  connect() {
    document.addEventListener('turbolinks:load', this.configDateInput)
  }

  disconnect() {
    document.removeEventListener('turbolinks:load', this.configDateInput)
  }

  configDateInput() {
    // Smelly way to config flatpickr after its init
    this.dateInputTarget._flatpickr.config.maxDate = 'today'
  }
}
