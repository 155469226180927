// IDEA il faudrait que le quantity.name change quand le inputfield value change
import { Controller } from "stimulus"
export default class extends Controller {

  static targets = [
    'addQuantityButton'
  ]

  initialize() {
    this.count = 0;
  }

  enabledRangeField(event){
    event.preventDefault()
    var range = event.currentTarget.parentNode.querySelector(".range-slider-container")
    var food = event.currentTarget.parentNode.querySelector(".food-name")
    var quantity = event.currentTarget.parentNode.querySelector(".range-class")
    range.classList.remove('hidden')
    quantity.disabled = false
    quantity.name = quantity.name + `[${food.value}]`
  }
  
  checkInputField(event) {
    if (event.currentTarget.value !== "") {
      this.addQuantityButtonTarget.disabled = false;

    } else {
      this.addQuantityButtonTarget.disabled = true;
    }
  }
}